
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import useBreakpoints from 'vue-next-breakpoints';

import Card from '@/views/new-design/components/Card.vue';
import FormCheckInput from '@/components/FormCheckInput.vue';

import {
  Actions as DrawActions,
  Getters as DrawGetters,
} from '@/store/enums/DrawEnums';
import { Draw } from '@/models/DrawModel';

import { acronym } from '@/utils/text';
import toasts from '@/utils/toasts';

const drawInit = {} as unknown as Draw;

export default defineComponent({
  components: {
    Card,
    FormCheckInput,
  },
  data: () => ({
    loading: false,
    counterTimer: '',
    startDraw: false,
    showWinner: false,
    drawData: drawInit,
    drawLotData: {} as any,
    drawButtonEnable: false,
  }),
  computed: {
    ...mapGetters({
      draw: DrawGetters.GET_DRAW,
      actionErrors: DrawGetters.GET_ACTION_ERRORS,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    breadcrumbs() {
      return [
        {
          to: '/draws',
          text: 'Draws',
          current: false,
        },
        {
          to: `/draws/${this.$route.params.agent_id}`,
          text: 'Draw Details',
          current: true,
        },
      ];
    },
    drawWinner() {
      const state =
        this.drawLotData?.state && this.drawLotData?.state != null
          ? ` of ${this.drawLotData?.state}`
          : '';
      return `${this.drawLotData?.first_name} ${this.drawLotData?.last_name}${state}`;
    },
  },
  methods: {
    ...mapActions({
      fetchDraw: DrawActions.FETCH_DRAW,
      clearDraw: DrawActions.CLEAR_DRAW,
      pickDrawWinner: DrawActions.PICK_DRAW_WINNER,
    }),

    async getDraw() {
      this.loading = true;

      await this.fetchDraw({ id: this.$route.params.draw_id }).then(() => {
        this.loading = false;
      });
    },

    onPageChanged() {
      console.log('test');
    },

    getAcronym(name = '') {
      return acronym(name);
    },
    startDrawLots() {
      if (!this.drawButtonEnable) return;

      if (this.startDraw) return;

      this.startDraw = true;

      const params = {
        drawId: this.drawData.id,
      };

      return this.pickDrawWinner(params)
        .then(async (data) => {
          if (data) {
            this.drawLotData = data.data;

            let timer = 5;

            const handle = setInterval(() => {
              this.counterTimer = timer.toString();
              timer--;
              if (timer == 0) {
                this.startDraw = false;
                this.showWinner = true;
                clearInterval(handle);
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    returnToDraw() {
      this.startDraw = false;
    },
    returnToDrawLists() {
      return this.$router.push({
        name: 'draw-lists',
      });
    },
    handleEnableDraw() {
      this.drawButtonEnable = this.drawButtonEnable ?? this.drawButtonEnable;
    },
  },
  async mounted() {
    await this.getDraw();
  },
  watch: {
    async draw(values) {
      this.drawData = (await values) as Draw;
    },
  },
});
