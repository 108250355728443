
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    label: { default: '' },
    modelValue: { default: false },
  },
  emits: ['update:modelValue'],
  computed: {
    checked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
